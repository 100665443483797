.navbar {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #333;
  color: white;
}

.navbar a {
  color: white;
  text-decoration: none;
}

.navbar a:hover {
  text-decoration: underline;
}

.project-card, .blog-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}

.about-page, .home-page, .contact-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.dark-mode .navbar {
  background-color: #1f1f1f;
}

.dark-mode .project-card, .dark-mode .blog-card {
  background-color: #1f1f1f;
  border-color: #333;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.dark-mode .footer {
  background-color: #1f1f1f;
}

.dark-mode-toggle {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  z-index: 1000;
}

.dark-mode .dark-mode-toggle {
  background-color: #333;
  color: #fff;
  border-color: #555;
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: white;
  margin-top: 20px;
}

.footer .social-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.footer .social-links a:hover {
  text-decoration: underline;
}

.contact-page {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.contact-page form {
  display: flex;
  flex-direction: column;
}

.contact-page input,
.contact-page textarea {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-page button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-page button:hover {
  background-color: #0056b3;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.back-to-top button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.back-to-top button:hover {
  background-color: #0056b3;
}

.not-found {
  text-align: center;
  padding: 50px;
}

.not-found h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.not-found p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.not-found a {
  color: #007bff;
  text-decoration: none;
}

.not-found a:hover {
  text-decoration: underline;
}

.skills-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skills-list li {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

.testimonials {
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.testimonials h2 {
  margin-bottom: 20px;
}

.testimonials-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.testimonial-card {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  max-width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.testimonial-text {
  font-style: italic;
  margin-bottom: 10px;
}

.testimonial-author {
  font-weight: bold;
  color: #007bff;
}

.newsletter {
  padding: 20px;
  background-color: #007bff;
  color: white;
  text-align: center;
}

.newsletter h2 {
  margin-bottom: 20px;
}

.newsletter form {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.newsletter input {
  padding: 10px;
  border: none;
  border-radius: 4px;
  width: 250px;
}

.newsletter button {
  padding: 10px 20px;
  background-color: white;
  color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.newsletter button:hover {
  background-color: #f1f1f1;
}

.newsletter-message {
  margin-top: 10px;
}

.resume-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.resume-link:hover {
  background-color: #0056b3;
}

.blog-editor {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.blog-editor input,
.blog-editor textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.blog-editor textarea {
  height: 150px;
}

.blog-editor button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.blog-editor button:hover {
  background-color: #0056b3;
}

.skills-progress {
  max-width: 600px;
  margin: 0 auto;
}

.skill {
  margin-bottom: 20px;
}

.progress-bar {
  background-color: #e0e0e0;
  border-radius: 4px;
  height: 10px;
  overflow: hidden;
}

.progress {
  background-color: #007bff;
  height: 100%;
}